import { template as template_62c6f02f2c664b8db3daf007e64c8e5f } from "@ember/template-compiler";
const FKControlMenuContainer = template_62c6f02f2c664b8db3daf007e64c8e5f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
