import { template as template_689d480d34b74c5e941c7aeef8b32cbd } from "@ember/template-compiler";
const SidebarSectionMessage = template_689d480d34b74c5e941c7aeef8b32cbd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
